import request from '@/util/request';

export function getList() {
    return request({
        url: '/company-tag',
        method: 'get'
    });
};

export function save(data) {
    return request({
        url: '/company-tag',
        method: 'post',
        data: data
    });
}

export function remove(id) {
    return request({
        url: `/company-tag/${id}`,
        method: 'delete'
    });
}
