<template>
    <div>
        <TitleBar>企业标签</TitleBar>
        <div class="main-container">
            <h1 class="top-title">企业标签由企业统一配置，配置后，企业员工可以对客户打标签</h1>
            <div class="add-btn"><Button @click="onShowDialog(null)" icon="md-add">添加标签分类</Button></div>
            <ul class="tag-groups">
                <li class="tag-group" v-for="group in tagGroups" :key="group.groupId">
                    <h2>{{ group.groupName }}</h2>
                    <div class="tags">
                        <Tag size="medium" color="default" class="t-tag" v-for="tag in group.tagItemList" :key="tag.id">{{ tag.name }}</Tag>
                    </div>
                    <button class="edit" @click="onShowDialog(group)">编辑</button>
                </li>
            </ul>
        </div>
        <Modal v-model="showDialog" width="600" :title="dialogTitle" class="tag-dialog">
            <Form :model="groupForm" label-position="top" style="padding: 0 8px">
                <FormItem label="标签分类名称" required>
                    <Input v-model="groupForm.groupName" />
                </FormItem>
                <FormItem label="标签" required>
                    <div class="tag-list">
                        <Tag
                            size="medium"
                            :color="tag.id | tagFilter"
                            v-for="(tag, index) in groupForm.tagItemList"
                            :key="index"
                            :name="index"
                            closable
                            class="tag-item"
                            @on-close="handleClose"
                            >{{ tag.name }}</Tag
                        >
                        <Input
                            ref="newTagInput"
                            v-if="editTag"
                            @keyup.enter.native="saveTag"
                            @on-blur="editTag = false"
                            v-model="newTagName"
                            style="width: 82px"
                        />
                        <Button v-if="!editTag" icon="md-add" @click="editNewTag">标签</Button>
                    </div>
                </FormItem>
            </Form>
            <div slot="footer" class="btn-list">
                <button class="delete-group" @click="remove" v-if="groupForm.groupId">删除标签分类</button>
                <Button @click="showDialog = false">取消</Button>
                <Button type="primary" @click="save">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import { getList, save, remove } from "@/api/company-tag";

export default {
    components: {
        TitleBar,
    },
    created() {
        this.getList();
    },
    filters: {
        tagFilter(id) {
            if (id > 0) {
                return "default";
            }
            return "primary";
        },
    },
    data() {
        return {
            tagGroups: [],
            showDialog: false,
            groupForm: {
                id: 0,
                name: "",
                tagItemList: [],
            },
            newTagName: "",
            editTag: false,
        };
    },
    computed: {
        dialogTitle() {
            if (this.groupForm.id) {
                return "编辑标签分类";
            }
            return "新建标签分类";
        },
    },
    methods: {
        getList() {
            getList().then((response) => {
                this.tagGroups = response;
            });
        },
        resetDialog() {
            this.groupForm = {
                groupId: 0,
                groupName: "",
                tagItemList: [],
            };
        },
        onShowDialog(group) {
            this.resetDialog();
            if (group) {
                this.groupForm.groupId = group.groupId;
                this.groupForm.groupName = group.groupName;
                this.groupForm.tagItemList = JSON.parse(JSON.stringify(group.tagItemList));
            }
            this.showDialog = true;
        },
        editNewTag() {
            this.editTag = true;
            this.$nextTick(() => {
                this.$refs.newTagInput.focus();
            });
        },
        saveTag() {
            if (this.newTagName) {
                this.groupForm.tagItemList.push({
                    id: 0,
                    name: this.newTagName,
                });
                this.newTagName = "";
            }
            this.editTag = false;
        },
        handleClose(event, index) {
            this.groupForm.tagItemList.splice(index, 1);
        },
        save() {
            if (!this.groupForm.groupName) {
                this.$Message.error("请输入标签分类名称！");
                return;
            }
            save(this.groupForm).then(() => {
                this.$Message.success("保存成功！");
                this.showDialog = false;
                this.getList();
            });
        },
        remove() {
            this.$Modal.confirm({
                title: "确定删除标签分类吗？",
                content: "<p>删除后标签分类无法恢复，如果确认删除标签分类，请点击确定。</p>",
                onOk: () => {
                    remove(this.groupForm.groupId).then(() => {
                        this.$Message.success("删除成功！");
                        this.showDialog = false;
                        this.getList();
                    });
                },
            });
        },
    },
};
</script>

<style lang="less" scoped>
.main-container {
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d4dbe1;
        border-radius: 3px;
    }
}

.tag-dialog {
    .tag-list {
        display: flex;
        flex-flow: row wrap;

        .tag-item {
            margin-right: 8px;
            margin-bottom: 8px;
        }
    }
}

.top-title {
    font-size: 16px;
    color: #0a2a4c;
    margin-top: 4px;
    height: 24px;
    line-height: 24px;
}

.add-btn {
    margin-top: 24px;
}

.tag-groups {
    margin-top: 24px;
    background-color: #fff;
    padding: 0;
    list-style: none;
    border: 1px solid #e8eaec;
    border-radius: 3px;

    .tag-group {
        border-bottom: 1px solid #e8eaec;
        display: flex;
        justify-content: space-between;

        &:last-child {
            border-bottom: none;
        }
    }

    h2 {
        margin: 30px 24px;
        font-size: 20px;
        font-weight: normal;
        line-height: 28px;
        width: 80px;
        flex: 0 0 80px;
    }

    .tags {
        flex: 1 1 auto;
        display: flex;
        flex-flow: row wrap;
        align-content: center;
        margin: 20px 40px 20px 70px;

        .t-tag {
            margin: 8px 20px;
        }
    }

    .edit {
        flex: 0 0 32px;
        margin: 32px 24px;
        border: none;
        color: @fc-main-color;
        outline: none;
        background-color: transparent;
        font-size: 16px;
        cursor: pointer;
    }
}

.delete-group {
    float: left;
    background-color: transparent;
    color: #ed4014;
    font-size: 12px;
    border: none;
    outline: none;
    cursor: pointer;
    height: 32px;
    line-height: 32px;
}
</style>